import React from 'react'
import PropTypes from 'prop-types'
import { colors, presets } from '../utils/theme'
import CascadeLink from '../components/CascadeLink'
import Metadata from '../components/Metadata'
import { FaCheck } from 'react-icons/fa';
import FeaturedIn from '../components/FeaturedIn'
import useSiteMetadata from '../components/SiteMetadata'
import { get } from 'lodash'
import { graphql } from 'gatsby'

import houseCircle from '../img/house-circle.svg'
import { Header, Footer } from './lt-landing-page'

const orange = 'rgba(241,89,42,1)'

const contentWidth = {
  width: "100%",
  maxWidth: '1000px',
  margin: '0 auto',
  padding: '0 20px',
}

const styles = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...contentWidth,
    maxWidth: '1000px',
  },
  topRow: {
    backgroundColor: colors.darkGreen,
    display: 'flex',
    width: '100%',
  },
  pretext: {
    color: colors.shamrock,
    fontWeight: 500,
    marginTop: '30px',
    fontSize: '18px',
    [presets.Tablet]: {
      marginTop: '30px',
      fontSize: '22px',
    },
  },
  h1: {
    color: 'white',
    marginBottom: 0,
    marginTop: 0,
    fontSize: '25px',
    lineHeight: '25px',
    fontWeight: 600,
    [presets.Tablet]: {
      fontSize: '40px',
      lineHeight: '40px',
    }
  },
  subtext: {
    margin: '0',
    color: 'white',
    fontWeight: 500,
    fontSize: '16px',
    marginBottom: '5px',
    marginTop: '15px',
    [presets.Tablet]: {
      marginTop: '10px',
      fontSize: '20px',
    }
  },
  subtextLine: {
    marginBottom: '5px',
  },
  button: {
    borderRadius: '5px',
    marginTop: '20px',
    textDecoration: 'none',
    textAlign: 'center',
    marginBottom: '30px',
    padding: '0 8px 0 8px',
    fontSize: '18px',
    backgroundColor: orange,
    color: 'white',
    ':hover': {
      // hack to make dark hover for any color
      boxShadow: 'inset 0 0 0 99999px rgba(0,0,0,0.2)',
    },
    width: '100%',
    maxWidth: '296px',
    height: '43px',
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    [presets.Tablet]: {
      flexDirection: 'row',
      paddingBottom: '0px',
    },
  },
  halfRow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  houseCircle: {
    width: '100%',
    margin: 0,
    maxWidth: '250px',
  },
  secondRow: {
    backgroundColor: 'white',
    color: colors.grey,
    paddingBottom: '30px',
  },
  checkRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '40px',
    [presets.Tablet]: {
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  checkItemHolder: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    [presets.Tablet]: {
      margin: 0
    },
  },
  checkmark: {
    marginRight: '10px',
  },
  checkText: {
    fontSize: '18px',
    fontWeight: 600,
    maxWidth: '280px',
  },
  checkSubText: {
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: 300,
    maxWidth: '280px',
  },
}

const MCMLandingPageTemplate = ({
  title,
  subTextLines,
  buttonText,
  checks,
  targetUrl,
  disclaimerText,
  helmet,
  pretext,
  pageTitle,
}) => {
  return (
    <div css={{ display: 'flex', flex: 1, flexDirection: 'column', minHeight: '100vh'}}>
      {helmet || ''}
      <Header />
      <div css={styles.topRow}>
        <div css={styles.contentContainer}>
          <div css={styles.row}>
            <div css={[styles.halfRow, {flex: 1.2}]}>
              {pretext && 
                <h3 css={styles.pretext}>
                  {pretext}
                </h3>
              }
              <h1 css={styles.h1}>
                {pageTitle || "Let's find out how much you can save"}
              </h1>
              <div css={styles.subtext}>
                {subTextLines && subTextLines.map((l, i)=> <div css={styles.subtextLine} key={i}>{l.text}</div>)}
              </div>
              <CascadeLink
                css={styles.button}
                href={targetUrl}
                analyticsTargetName='CTA Button'
              >
                { buttonText || "Get Started"}
              </CascadeLink>
            </div>
            <div css={[styles.halfRow, {alignItems: 'center', justifyContent: 'center', [presets.Tablet]: { display: 'flex'}}]}>
              <img alt="house-circle" src={houseCircle} css={styles.houseCircle}/>
            </div>
          </div>
        </div>
      </div>
      <div css={styles.secondRow}>
        <div css={styles.contentContainer}>
          <div css={styles.checkRow}>
            {checks.map(c => (
              <div key={c.text} css={styles.checkItemHolder}>
                <FaCheck color={colors.shamrock} size={22} css={styles.checkmark}/>
                <div css={styles.checkText}>
                  <div>{c.text}</div>
                  <div css={styles.checkSubText}>
                    {c.subText}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <FeaturedIn />
      </div>
      <Footer disclaimerText={disclaimerText}/>
    </div>
  )
}

MCMLandingPageTemplate.propTypes = {
  pretext: PropTypes.string,
  title: PropTypes.string,
  subTextLines: PropTypes.array,
  buttonText: PropTypes.string,
  checks: PropTypes.array,
  targetUrl: PropTypes.string,
  pageTitle: PropTypes.string,
}

const MCMLandingPage = ({ data, location }) => {
  const { markdownRemark: post } = data
  const { siteUrl } = useSiteMetadata()
  const imagePath = get(post.frontmatter, 'featuredImage.childImageSharp.fixed.src')
  const image = imagePath ? siteUrl + imagePath : null


  return (
    <MCMLandingPageTemplate
      helmet={
        <Metadata
          title={post.frontmatter.title}
          titleTemplate={null}
          description={post.frontmatter.description}
          image={image}
          tags={post.frontmatter.tags}
        />
      }
      pretext={post.frontmatter.pretext}
      title={post.frontmatter.title}
      subTextLines={post.frontmatter.subTextLines}
      buttonText={post.frontmatter.buttonText}
      calloutTitle={post.frontmatter.calloutTitle}
      calloutSubText={post.frontmatter.calloutSubText}
      checks={post.frontmatter.checks}
      targetUrl={post.frontmatter.targetUrl}
      disclaimerText={post.htmlAst}
      pageTitle={post.frontmatter.pageTitle}
    />
  )
}

MCMLandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default MCMLandingPage

export const pageQuery = graphql`
  query MCMLandingPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        pretext
        title
        tags
        pageTitle
        subTextLines {
          text
        }
        buttonText
        checks {
          text
          subText
        }
        targetUrl
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 400, quality: 100, layout: FIXED)
          }
        }
      }
    }
  }
`
